import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import SectionTitle from "../components/sectiontitle";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function( {data} ) {
    return (
        <Layout>
            <SEO lang="en" title="Photography" />
            <section id="art" className="container" style={{ minHeight: "600px" }}>
                <div className="section-title body-max-readable">
                    <SectionTitle title="Photography" />
                        <p className="text-primary">
                        The Art of Geography's use of panoramic virtual reality was conceived as 
                        a way to create a visual documentary of special places in North America. 
                        Natural or wilderness lands provide refuge and inspiration for people as 
                        well as habitat for other species.
                        </p>

                        <div className="center pad-over-and-under">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.vrlog.com/360/sauk-mountain-nr-5/" 
                                title="Sample panorama of Sauk Mountain">
                                <Img fluid={data.fileName.childImageSharp.fluid} alt="An example 
                                    panorama from Sauk Mountain from the thousands created by 
                                    the Art of Geography" />
                            </a>
                            <div className=" pad-above-10px">
                                <span className="aog-caption">Sauk Mountain, Washington 
                                (click to view as a 360 degree VR in a new window)</span>
                            </div>
                        </div>

                        <p className="text-primary">The pressure of population, economic growth, 
                        park budget cuts, climate change, and catastrophic events often encroaches 
                        upon our green spaces and wilderness lands. It is my hope that through 
                        experiencing the special beauty of our wild lands, the public will better 
                        understand the value of open space and wilderness and want to preserve 
                        it for future generations to enjoy.
                        </p>

                        <p className="text-primary">When shooting these scenes, there was also a 
                        sense that many of these locations will never be the same again; some 
                        may improve, some may be destroyed--because geological and environmental 
                        change is inevitable. People usually think of big changes only occurring 
                        in millennial timescales, but all it takes is one natural disaster 
                        (Indian Ocean tsunami, Mt. St. Helens, earthquake on the San Andreas 
                        fault) to devastate an area for generations. Not to mention man-made 
                        disasters such as giant forest fires. These panoramas provide a unique 
                        documentary record of what specific places looked like at recorded 
                        moments in history.
                        </p>

                        <p className="text-primary">The tremendous amount of effort that goes 
                        into making these immersive experiences a way of honoring a moment, 
                        a sentient awareness, and a place. Many of these scenes might require 
                        ten thousand words to describe; yet the power of this medium is that 
                        one immersive panorama can impart even more than those many words. 
                        If you think any of these virtual reality panoramas are impressive, 
                        consider that most people who view the same panorama as a four to 
                        five foot wide print find the print more striking.
                        </p>

                        <p className="text-primary">One of the most gratifying results has 
                        been how people who might not otherwise be able to visit a wilderness 
                        location could enjoy them on VirtualParks.org. Thank you for your support.
                        </p>

                        <h4>Outline of the panoramic process</h4>
 
                        <ol className="body-content-list">
                            <li>Choose a scene you want to capture</li>
                            <li>Shoot overlapping images of the scene using a camera</li>
                            <li>Transfer or scan the images into a computer</li>
                            <li>Stitch the images together using software that can stitch photos together</li>
                            <li>Build a virtual reality scene file from the stitched image</li>
                            <li>Publish the virtual reality scene file on the web</li>
                        </ol>


 {/* How I do it
 1. I usually hike to interesting scenes and carry my gear in a daypack.
 
 2. I use different cameras depending on the portability requirements. 
 All my panoramas are composite images, stitched together from between 12 and 18 
 separate overlapping images. The camera is mounted sideways on the tripod or 
 monopod using a panhead. I use different panheads depending on how much weight 
 I want to carry.
 
 3. I transfer digital camera images to the computer via USB, or scan the film-based 
 scenes myself using a scanner.
 
 4. I use a variety of stitchers to stitch the images together.
 
 5. The same software usually creates the virtual reality scene file from the stitched image.
 
 All my panoramas are composite images, stitched together from 12 (sometimes up to 18) 
 separate overlapping images.
 
 Photography: During my first few years of shooting scenes, all panoramas were captured 
 using a Canon T90 and Canon FD17mm rectilinear lens atop a Bogen 3246 or 3221 tripod 
 using Kodak Gold 400 speed color negative film, scanned onto PhotoCD. For the following 
 few years all photos were taken with a Nikon N90. I use a variety of film... usually 
 Kodak but for different situations I switch to Agfa or Fuji. I scan the film myself, 
 for complete control over reproducing what I saw at the scene. Lately I've been 
 using with Nikon digital cameras. 
 
 QuickTime/VR: Scenes up to mid-1997 were created using Apple's QTVR Authoring Tools 
 v1.0. After that I started using Apple's QTVR Authoring Studio v1.01. In almost 
 all cases each scene was built from twelve photos taken at 30 degree intervals. 
 The QTVR Authoring software stitches the photos together, blending and sharpening 
 the final composite panorama. I then opened the resulting PICT file in Photoshop 
 and made adjustments to compensate for variation imperfections, such as dust on 
 the negative. Finally the scene is "diced" and converted into a QuickTime movie 
 by the authoring tools. Finally the QuickTime files are uploaded using the 
 automatic site upload function of Dreamweaver.  */}
 
                       <p>&nbsp; </p>
                        <div className="align-left">
                            <a href="/portfolio/photography" className="btn">
                                View photo portfolio
                            </a>
                        </div>


                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "sauk-mtn-nr5-2000px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 333) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`